import React from 'react'
import styled from 'styled-components'

const CardContainerBody = styled.div`
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-auto-rows: 1fr;
`

const CardGrid = (props) => {
  return (
    <CardContainerBody>
      {props.children}
    </CardContainerBody>
  )
}

export default CardGrid
