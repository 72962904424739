import randomNumber from '../helpers/helpers'

const data = [
  {
    brand: "Wild Fang",
    image: "3_mrpejF6kPlWEQ0MuApg2X4lmECvcmHVw-1.jpg",
    shirtName: "The Ultimate WF Button Up",
    url: "https://www.wildfang.com/the-ultimate-wf-fern-button-up.html",
    number: randomNumber(10, 5)
  },
  {
    brand: "Zara",
    image: "zara_8351124330_2_1_1.jpg",
    shirtName: "Floral Print Shirt",
    url: "https://www.zara.com/us/en/floral-print-shirt-p08351124.html?v1=6778110&v2=1074561",
    number: randomNumber(10, 5)
  },
  {
    brand: "Wednesay's Girl",
    image: "asos_9960763-2.jpeg",
    shirtName: "Cropped Boxy Shirt",
    url: "https://us.asos.com/wednesdays-girl/wednesdays-girl-cropped-boxy-shirt-in-palm-floral-two-piece/prd/9960763?CTAref=We%20Recommend%20Carousel_2&featureref1=we%20recommend%20pers",
    number: randomNumber(10, 5)
  },
  {
    brand: "Ralph Lauren",
    image: "macys_10297654_fpx.jpeg",
    shirtName: "Floral-Print Blouse",
    url: "https://www.macys.com/shop/product/lauren-ralph-lauren-floral-print-blouse?ID=6913754&CategoryID=74459#fn=sp%3D1%26spc%3D427%26ruleId%3D78%26searchPass%3DmatchNone%26slotId%3D31",
    number: randomNumber(10, 5)
  },
  {
    brand: "Hilo Hattie",
    image: "IMG_9999_7_1_2048x.jpeg",
    shirtName: "Floral Women's Camp Blouse",
    url: "https://www.hilohattie.com/collections/blouses-tops/products/copy-of-floral-womens-camp-blouse-purple-need-pic-1?variant=26307087937",
    number: randomNumber(10, 5)
  },
  {
    brand: "Wild Fang",
    image: "wildfang_8_GaEjaRl9Bf8RHUkqm78pCywr3iiAqaVf-1.jpg",
    shirtName: "Marshall Shirt",
    url: "https://www.wildfang.com/marshall-shirt.html",
    number: randomNumber(10, 5)
  },
  {
    brand: "Vans",
    image: "vans_9791214-1-floral.jpeg",
    shirtName: "Tropical Hawaiian Print Shirt",
    url: "https://us.asos.com/vans/vans-tropical-hawaiian-print-shirt/prd/9791214?affid=14174&channelref=product+search&mk=abc&currencyid=2&ppcadref=753857711%7C38363067726%7Caud-456822746624%3Apla-515549277997&browsecountry=US&_cclid=v3_ce1db9fb-e030-56b6-b8ee-74314d922c82&gclid=EAIaIQobChMI-__44_Cs3wIVkK_ICh2KnwWFEAQYDyABEgIW__D_BwE",
    number: randomNumber(10, 5)
  },
  {
    brand: "The Kooples",
    image: "FCCC17005KBLU01_A.jpg",
    shirtName: "Blue Silk Shirt with Hawaiian Print",
    url: "https://www.thekooples.com/us/blue-silk-shirt-with-hawaii-print-1536637.html?wiz_medium=SEM&wiz_source=google&wiz_campaign=1564368219&gclid=EAIaIQobChMI-__44_Cs3wIVkK_ICh2KnwWFEAQYDiABEgLEVfD_BwE",
    number: randomNumber(10, 5)
  },
  {
    brand: "Ralph Lauren",
    image: "10149822_fpx.jpeg",
    shirtName: "Floral-Print Cotton Shirt",
    url: "https://www.macys.com/shop/product/lauren-ralph-lauren-floral-print-cotton-shirt?ID=6789431&tdp=cm_app~zMCOM-NAVAPP~xcm_zone~zPDP_ZONE_B~xcm_choiceId~zcidM06MAU-d39db09d-7188-4e6f-8690-38d8973f4b34%40H8%40customers%2Balso%2Bloved%24255%246789431~xcm_pos~zPos3~xcm_srcCatID~z255",
    number: randomNumber(10, 5)
  },
]

export default data
