import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components'

import CardGrid from './components/CardGrid'
import CardGridContainer from './components/CardGridContainer'
import Card from './components/Card'
import Intro from './components/Intro'
import data from './data/data'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Roboto";
  }
`
class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }

    this.openApp = this.openApp.bind(this)
  }

  buildGrid(numArray) {
    const grid = data.map((dataElement, index) => {
      const imageUrl =`/images/${dataElement.image}`
      return (
        <Card
          key={index}
          number={dataElement.number}
          brand={dataElement.brand}
          shirtName={dataElement.shirtName}
          image={imageUrl}
          url={dataElement.url}
        />
      )
    })

    return grid
  }

  openApp() {
    this.setState({ open: true })
  }

  render() {
    return (
      <div className="App">
        <CardGridContainer>
          <CardGrid>
            {this.buildGrid()}
          </CardGrid>
        </CardGridContainer>
        <Intro open={this.state.open} openApp={this.openApp} />

      </div>
    );
  }
}

export default App;
