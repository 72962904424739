import React from 'react'
import styled, { keyframes } from 'styled-components'

const gradient = keyframes`
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
`

const cardMargin = "2rem"

const CardImage = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: calc(100% - ${cardMargin});
  height: calc(100% - ${cardMargin});
  background-color: white;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const CardGradient = styled.div`
  background-size: 400% 400%;
  transition: all 0.3s ease;
  background-image: linear-gradient(-45deg, red, green, red, green);
  animation: ${gradient} 3s ease infinite;
  position: absolute;
  width: 100%;
  height: 100%;
`

const CardText = styled.div`
  display: flex;
  top: 1rem;
  left: 1rem;
  width: calc(100% - ${cardMargin});
  height: calc(100% - ${cardMargin});
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: "Raleway", "Arial";

  h2 {
    color: black;
    font-weight: 500;
    font-size: 1.25rem;
    margin: 0
    text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.5);
  }

  h3 {
    color: black;
    font-weight: 300;
    font-size: 0.825rem;
    margin: 0
    text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.5);
  }
`

const CardOverlay = styled.div`
  top: 1rem;
  left: 1rem;
  width: calc(100% - ${cardMargin});
  height: calc(100% - ${cardMargin});
  background-color: white;
  position: absolute;
`

const Card = (props) => {
  const CardBox = styled.a`
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 1px;
    box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.0);
    transform: scale(1);
    transition: all 0.3s ease;
    display: block;

    .card-text {
      transition: all 0.3s ease;
      opacity: 1;
    }

    .card-gradient {
      opacity: 0;
    }

    .card-overlay {
      opacity: 0.8;
    }

    &::before {
      padding-bottom: 100%;
      content: "";
      display: block;
      float: left;
    }

    &:hover {
      box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.3);
      transform: scale(1.05) rotate(${props.number}deg);

      .card-text {
        opacity: 0;
      }

      .card-gradient {
        opacity: 1;
      }

      .card-overlay {
        opacity: 0;
      }
    }
  `

  return (
    <CardBox href={props.url} target="_blank">
      <CardGradient className="card-gradient" />
      <CardImage>
        <img src={props.image} />
      </CardImage>
      <CardOverlay className="card-overlay" />
      <CardText className="card-text">
        <h2>
          {props.shirtName}
        </h2>
        <h3>
          {props.brand}
        </h3>
      </CardText>
    </CardBox>
  )
}


export default Card
